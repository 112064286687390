import React from 'react';

import { Body2, ButtonText, Subtitle1 } from '../Typography';
import { ReactComponent as SuccessIcon } from '../../assets/svg/success-icon-with-transparent-bg.svg';

import styles from './index.module.scss';

export interface BannerWithTagAndButtonProps {
  image: string;
  eyebrow: string;
  title: string;
  description: string;
  buttonText: string;
  buttonUrl: string;
}

const BannerWithTagAndButton: React.FC<BannerWithTagAndButtonProps> = ({
  image,
  eyebrow,
  title,
  description,
  buttonText,
  buttonUrl
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img className={styles.image} src={image} alt={title} />
        <div className={styles.tag}>
          <SuccessIcon className={styles.successIcon} />
          <Body2 className={styles.tagName}>{eyebrow}</Body2>
        </div>

        <Subtitle1 className={styles.title}>{title}</Subtitle1>
        <Body2 className={styles.description}>{description}</Body2>

        <a
          href={buttonUrl}
          className={styles.link}
          target='_blank'
          rel='noopener noreferrer'
        >
          <ButtonText>{buttonText}</ButtonText>
        </a>
      </div>
    </div>
  );
};

export default BannerWithTagAndButton;
